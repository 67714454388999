import Hero from "../../components/summer/Hero";
import SummerSubHeroPhotos from "../../components/summer/SummerSubHeroPhotos";
import SummerInfo from "../../components/summer/SummerInfo";
import Activities from "../../components/summer/Activities";
import PhotosActiviteiten from "../../components/summer/PhotosActiviteiten";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import HeroInfo from "../../components/summer/HeroInfo";

const Summer = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title> {t("summer.meta_title")}</title>
        <meta name="description" content={t("summer.meta_description")} />
        <link rel="canonical" href="https://riesneralm.com/summer/" />
      </Helmet>

      <Hero />
      <SummerSubHeroPhotos />
      <HeroInfo />
      <SummerInfo />
      <Activities />
      <PhotosActiviteiten />
    </>
  );
};

export default Summer;
