import { Trans, useTranslation } from "react-i18next";
import { MdOutlineHiking } from "react-icons/md";
import { GiArcheryTarget, GiRaceCar, GiHangGlider } from "react-icons/gi";
import { TbRollercoaster, TbBeach } from "react-icons/tb";
import { IoGolfOutline } from "react-icons/io5";

import { LinkText } from "../LinkText";

const Activities = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-[1400px] m-auto w-full px-4 py-8  bg-gray-50/60 shadow-lg border rounded-lg">
      <div className="flex flex-col place-items-center">
        <h2 className=" text-4xl text-indigo-600 md:text-6xl font-bold ">
          {t("activities.title")}
        </h2>

        <p className="text-gray-500  uppercase mb-4  tracking-wider text-xs md:text-lg">
          {t("activities.subtitle")}
        </p>
      </div>
      <div className="text-gray-700 mb-4 tracking-wide text-sm sm:text-sm md:text-lg grid grid-cols-1 md:grid-cols-2 sm:grid">
        <div className="md:col-span-2 flex items-center flex-col">
          <div className="sm:grid  md:grid md:grid-cols-2 gap-12">
            <ul className="mt-4 tracking-wide text-gray-700">
              <li className="flex items-center mb-8 border-2 rounded-md p-4">
                <div className="flex flex-col">
                  <div className="flex items-center flex-row">
                    <IoGolfOutline
                      size={30}
                      className="my-2 mr-4  text-indigo-600  flex-shrink-0"
                    />
                    <h2 className="text-xl md:text-3xl font-semibold text-indigo-600 ml:4">
                      {t("activities.title_1")}
                    </h2>
                  </div>
                  <div>
                    <Trans
                      i18nKey="activities.item_1"
                      t={t}
                      components={{
                        lnk1_1: <LinkText to="https://golfpichlarn.at" />,
                        lnk1_2: <LinkText to="http://www.schladming-golf.at" />,
                        lnk1_3: <LinkText to="http://www.glcennstal.at" />,
                      }}
                    />
                  </div>
                </div>
              </li>

              <li className="flex items-center mb-8 border-2 rounded-md p-4">
                <div className="flex flex-col">
                  <div className="flex items-center flex-row">
                    <GiArcheryTarget
                      size={40}
                      className="my-2 mr-4 text-indigo-600 flex-shrink-0"
                    />
                    <h2 className="text-xl md:text-3xl font-semibold text-indigo-600 ml:4">
                      {t("activities.title_2")}
                    </h2>
                  </div>

                  <div>
                    <Trans
                      i18nKey="activities.item_2"
                      t={t}
                      components={{
                        lnk2_1: <LinkText to="http://www.schaupphof.com" />,
                      }}
                    />
                  </div>
                </div>
              </li>

              <li className="flex items-center mb-8 border-2 rounded-md p-4">
                <div className="flex flex-col">
                  <div className="flex items-center flex-row">
                    <GiRaceCar
                      size={40}
                      className="my-2 mr-4 text-indigo-600 flex-shrink-0"
                    />
                    <h2 className="text-xl md:text-3xl font-semibold text-indigo-600 ml:4">
                      {t("activities.title_3")}
                    </h2>
                  </div>

                  <div>
                    <Trans
                      i18nKey="activities.item_3"
                      t={t}
                      components={{
                        lnk3_1: (
                          <LinkText to="https://www.mountain-gokart.at" />
                        ),
                      }}
                    />
                  </div>
                </div>
              </li>
              <li className="flex items-center mb-8 border-2 rounded-md p-4">
                <div className="flex flex-col">
                  <div className="flex items-center flex-row">
                    <TbRollercoaster
                      size={40}
                      className="my-2 mr-4 text-indigo-600 flex-shrink-0"
                    />
                    <h2 className="text-xl md:text-3xl font-semibold text-indigo-600 ml:4">
                      {t("activities.title_5")}
                    </h2>
                  </div>

                  <div>
                    <Trans
                      i18nKey="activities.item_5"
                      t={t}
                      components={{
                        lnk5_1: <LinkText to="https://www.flyingcoaster.at" />,
                        lnk5_2: <LinkText to="https://www.zipline.at" />,
                        lnk5_3: <LinkText to="https://www.abenteuerpark.at" />,
                      }}
                    />
                  </div>
                </div>
              </li>
            </ul>
            <ul className="mt-0 sm:mt-0 md:mt-4 tracking-wide text-gray-700">
              <li className="flex items-center mb-8 border-2 rounded-md p-4">
                <div className="flex flex-col">
                  <div className="flex items-center flex-row">
                    <MdOutlineHiking
                      size={40}
                      className="my-2 mr-4 text-indigo-600 flex-shrink-0"
                    />
                    <h2 className="text-xl md:text-3xl font-semibold text-indigo-600 ml:4">
                      {t("activities.title_4")}
                    </h2>
                  </div>

                  <div>
                    <Trans
                      i18nKey="activities.item_4"
                      t={t}
                      components={{
                        lnk4_1: <LinkText to="https://www.flyingcoaster.at" />,
                      }}
                    />
                  </div>
                </div>
              </li>

              <li className="flex items-center mb-8 border-2 rounded-md p-4">
                <div className="flex flex-col">
                  <div className="flex items-center flex-row">
                    <GiHangGlider
                      size={40}
                      className="my-2 mr-4 text-indigo-600 flex-shrink-0"
                    />
                    <h2 className="text-xl md:text-3xl font-semibold text-indigo-600 ml:4">
                      {t("activities.title_6")}
                    </h2>
                  </div>

                  <div>
                    <Trans
                      i18nKey="activities.item_6"
                      t={t}
                      components={{
                        lnk6_1: (
                          <LinkText to="https://www.planai.at/en/summer/paragliding" />
                        ),
                      }}
                    />
                  </div>
                </div>
              </li>

              <li className="flex items-center mb-8 border-2 rounded-md p-4">
                <div className="flex flex-col">
                  <div className="flex items-center flex-row">
                    <TbBeach
                      size={40}
                      className="my-2 mr-4 text-indigo-600 flex-shrink-0"
                    />
                    <h2 className="text-xl md:text-3xl font-semibold text-indigo-600 ml:4">
                      {t("activities.title_7")}
                    </h2>
                  </div>

                  <div>
                    <Trans
                      i18nKey="activities.item_7"
                      t={t}
                      components={{
                        lnk4_1: <LinkText to="" />,
                      }}
                    />
                  </div>
                </div>
              </li>

              <div className="flex flex-col sm:flex sm:flex-col md:flex-col items-center border-2 rounded-md p-4">
                <div>
                  <h3>{t("activities.button_text")}</h3>
                </div>
                <div className="mt-4">
                  {/* <div className="flex  md:flex px-6 items-center"> */}
                  <a
                    href="https://www.villaforyou.com/ho-12357/AT.8953.06"
                    target="blank"
                  >
                    <button
                      className="px-8 py-2 text-xl text-white  bg-red-500 border-red-500
        hover:bg-red-600 hover:text-white rounded-md tracking-wide "
                    >
                      {t("button.check_availibility")}
                    </button>
                  </a>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activities;
