import riesneralmHiking from "../../assets/images/summer/riesneralm-hiking.webp";
import swimmingLake from "../../assets/images/summer/swimmingLake.webp";
import tauplitz from "../../assets/images/summer/tauplitz.webp";
import dachsteinGletscher from "../../assets/images/summer/dachsteinGletscher.webp";
import mountainGokart from "../../assets/images/summer/mountain-gokart.webp";
import flyingCoaster from "../../assets/images/summer/flyingCoaster.webp";
import foxTarget from "../../assets/images/summer/foxTarget.webp";
import golfSchlossPichlarn from "../../assets/images/summer/golfSchlossPichlarn.webp";

import paragliding from "../../assets/images/summer/paragliding.webp";

import ImageCard from "../ImageCard.jsx";

const PhotosActiviteiten = () => {
  var images = [
    {
      id: 1,
      src: riesneralmHiking,
      title: "Speurtocht voor kinderen op de Riesneralm",
      subtitle: "Donnersbachwald - riesneralm",
    },

    {
      id: 2,
      src: tauplitz,
      title: "Wandelen in het mooie Tauplitz",
      subtitle: "Tauplitz",
    },
    {
      id: 3,
      src: paragliding,
      title: "Paragliding",
      subtitle: "Donnersbachwald - Schladming",
    },
    {
      id: 4,
      src: foxTarget,
      title: "Boogschieten, leuk voor de kids",
      subtitle: "Donnersbachwald",
    },
    {
      id: 5,
      src: swimmingLake,
      title: "Afkoelen in de zwemvijver",
      subtitle: "Donnersbachwald",
    },
    {
      id: 6,
      src: mountainGokart,
      title: "Go Karts Fun",
      subtitle: "Höuchwurzen",
    },

    {
      id: 7,
      src: dachsteinGletscher,
      title: "Bezoek de Dachstein Gletscher",
      subtitle: "Schladming - Dachstein",
    },
    {
      id: 8,
      src: golfSchlossPichlarn,
      title: "Schloss Pichlarn Golf",
      subtitle: "Irdning",
    },
    {
      id: 9,
      src: flyingCoaster,
      title: "Avontuur Flying Coaster",
      subtitle: "Gröbming",
    },
  ];

  return (
    <>
      <div className="max-w-[1400px] m-auto w-full px-4 py-16">
        <div className="grid grid-col md:grid-cols-3 sm:grid-cols-3 gap-4 mx-auto">
          {images.map((image) => (
            <ImageCard key={image.id} image={image} />
          ))}
        </div>
      </div>
    </>
  );
};

export default PhotosActiviteiten;
