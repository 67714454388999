import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  Avatar,
  Paper,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useState } from "react";
import { Spinner } from "../../components/loader/Loader";
import { loginUser } from "../../services/authService";
import { SET_LOGIN, SET_USERNAME } from "../../redux/store/auth/authSlice";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
// import { CLEAR_USERS } from "../../redux/store/user/userSlice";
// import { persistor } from "../../redux/store";

const loginSchema = yup.object().shape({
  email: yup.string().email("invalid email").required("required"),
  password: yup.string().required("required"),
});

const initialValues = {
  email: "",
  password: "",
};

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialValues);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  // const clearReduxStore = async () => {
  //   await logoutUser();
  //   // toegevoegd 15-2 om te testen
  //   persistor.purge();
  //   dispatch(SET_LOGOUT());
  //   dispatch(SET_LOGIN(false));
  //   dispatch(SET_ADMIN(false));

  //   dispatch(SET_UID(""));
  //   dispatch(SET_FIRSTNAME(""));
  //   dispatch(SET_ROLE(""));
  //   dispatch(SET_PROFILEIMAGEPATH(""));

  //   dispatch(CLEAR_BOOKINGS());
  //   dispatch(CLEAR_CHALETS());
  //   dispatch(CLEAR_USERS());
  // };

  // clearReduxStore();

  const handleFormSubmit = async (values, onSubmitProps) => {
    setIsLoading(true);

    try {
      const data = await loginUser(formData);
      //console.log("Data:", data);
      if (data) {
        dispatch(SET_LOGIN(true));
        dispatch(SET_USERNAME(data.userName));

        setIsLoading(false);
        navigate("/webcam");
      }
    } catch (error) {
      setIsLoading(false);
    }

    // onSubmitProps.resetForm();
  };

  return (
    <>
      <Helmet>
        <title> {t("login.meta_title")}</title>
        <meta name="description" content={t("login.meta_description")} />
        <link rel="canonical" href="https://riesneralm.com/login/" />
      </Helmet>

      <div className="max-w-[1400px] m-auto w-full px-4 py-8 mt-16  bg-gray-50/60 shadow-lg border rounded-lg">
        {isLoading && <Spinner />}
        <Paper
          sx={{
            width: isNonMobile ? "50%" : "93%",
            padding: "2rem",
            margin: "1rem  auto",
            backgroundColor: "background.paper",
          }}
          elevation={3}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb="1rem"
          >
            <Avatar
              sx={{
                bgcolor: "secondary.main",
              }}
            >
              <LockOutlinedIcon />
            </Avatar>
          </Box>
          <Typography textAlign="center" variant="h4" sx={{ mb: "1.5rem" }}>
            Sign In
          </Typography>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={loginSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              resetForm,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <TextField
                    label="Email"
                    onBlur={handleBlur}
                    //onChange={handleChange}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.currentTarget.name]: e.currentTarget.value,
                      });
                      handleChange(e);
                    }}
                    value={values.email.toLowerCase()}
                    name="email"
                    error={Boolean(touched.email) && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    label="Password"
                    type="password"
                    onBlur={handleBlur}
                    // onChange={handleChange}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.currentTarget.name]: e.currentTarget.value,
                      });
                      handleChange(e);
                    }}
                    value={values.password}
                    name="password"
                    error={
                      Boolean(touched.password) && Boolean(errors.password)
                    }
                    helperText={touched.password && errors.password}
                    sx={{ gridColumn: "span 4" }}
                  />
                </Box>
                {/* BUTTONS */}
                <Box>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    sx={{
                      m: "2rem 0",
                      p: "1rem",
                    }}
                  >
                    LOGIN
                  </Button>
                  {/* <Typography
                  onClick={() => {
                    navigate("/register");
                    resetForm();
                  }}
                  color="text.disabled"
                  sx={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Don't have an account? Sign Up here.
                </Typography> */}
                </Box>
              </form>
            )}
          </Formik>
        </Paper>
      </div>
    </>
  );
};

export default Login;
