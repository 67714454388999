import { useTranslation } from "react-i18next";
import { FaFacebook, FaGooglePlusG, FaInstagram } from "react-icons/fa";
import { LanguageSelectorFooter } from "./LanguageSelector";

const Footer = () => {
  const { t } = useTranslation();
  return (
    /*    <div className="text-center w-full mt-24 px-2 bg-indigo-800 border m-auto py-2 text-slate-200 mb-4 md:text-left ">
     */
    <>
      <div className="w-full mt-24 bg-indigo-950 text-gray-300 py-2 px-2">
        <div className="max-w-[1400px] mx-auto grid md:justify-items-center grid-cols-1 gap-4 md:gap-0  md:grid-cols-4 border-b-2 border-gray-600 py-8 px-4">
          <div>
            <h6 className="font-bold uppercase pt-2 tracking-wider ">
              {t("footer.title_1")}
            </h6>
            <ul className="text-gray-400">
              <li className="py-1">Chalet Hirschblick</li>
              <li className="text-sm">Donnersbachwald 241</li>
              <li className="text-sm">8953 Irdning - Donnersbachtal</li>
              <li className="text-sm">Email: info@chalet-hirschblick.com</li>
              <li className="mt-10 text-sm  hover:underline hover:hover:underline-offset-4">
                <a
                  href="http://www.chalet-hirschblick.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  chalet-hirschblick.com
                </a>
              </li>
              <li className="text-sm  hover:underline hover:hover:underline-offset-4">
                <a
                  href="http://www.riesneralm.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  riesneralm.com
                </a>
              </li>
              <li className="text-sm  hover:underline hover:hover:underline-offset-4">
                <a
                  href="http://www.riesneralm.de"
                  target="_blank"
                  rel="noreferrer"
                >
                  riesneralm.de
                </a>
              </li>
              <li className="text-sm  hover:underline hover:hover:underline-offset-4">
                <a
                  href="http://www.riesneralm.nl"
                  target="_blank"
                  rel="noreferrer"
                >
                  riesneralm.nl
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h6 className="font-bold uppercase pt-2 tracking-wider">
              {t("footer.title_2")}
            </h6>

            <ul className="text-gray-400">
              <li className="py-1 hover:underline hover:hover:underline-offset-4">
                <a href="/">{t("menu.home")}</a>
              </li>
              <li className="py-1 hover:underline hover:hover:underline-offset-4">
                <a href="/winter">{t("menu.winter")}</a>
              </li>
              <li className="py-1 hover:underline hover:hover:underline-offset-4">
                <a href="/summer">{t("menu.summer")}</a>
              </li>
              <li className="py-1 hover:underline hover:hover:underline-offset-4">
                <a href="/webcam">{t("menu.webcam")}</a>
              </li>
              <li className="py-1 hover:underline hover:hover:underline-offset-4">
                <a href="/gallery">{t("menu.gallery")}</a>
              </li>
            </ul>
          </div>

          <div>
            <h6 className="font-bold uppercase pt-2 tracking-wider">
              {t("footer.title_3")}
            </h6>

            <ul className="text-gray-400">
              <LanguageSelectorFooter />
              {/* <li className="py-1 hover:underline hover:hover:underline-offset-4">
                <a href="/">{t("footer.language_1")}</a>
              </li>
              <li className="py-1 hover:underline hover:hover:underline-offset-4">
                <a href="#winter">{t("footer.language_2")}</a>
              </li>
              <li className="py-1 hover:underline hover:hover:underline-offset-4">
                <a href="/summer">{t("footer.language_3")}</a>
              </li> */}
            </ul>
          </div>

          <div>
            <h6 className="font-bold uppercase pt-2 tracking-wider">
              {t("footer.title_4")}
            </h6>

            <ul className="text-gray-400">
              <li className="py-1  hover:underline hover:hover:underline-offset-4">
                <a
                  href="https://www.schladming-dachstein.at/nl/winter/skibergen/riesneralm"
                  target="_blank"
                  rel="noreferrer"
                >
                  Riesneralm Pistes
                </a>
              </li>
              <li className="py-1  hover:underline hover:hover:underline-offset-4">
                <a href="http://www.steer.at/" target="_blank" rel="noreferrer">
                  Restaurant Steer
                </a>
              </li>
              <li className="py-1  hover:underline hover:hover:underline-offset-4">
                <a
                  href="https://www.sport-scherz.at/filiale-riesneralm-skiverleih-22"
                  target="_blank"
                  rel="noreferrer"
                >
                  Scherz Skiverleih
                </a>
              </li>
              <li className="py-1  hover:underline hover:hover:underline-offset-4">
                <a
                  href="https://www.spar.at/standorte/spar-donnersbach-8953-nr-12"
                  target="_blank"
                  rel="noreferrer"
                >
                  Spar Donnersbach
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="flex flex-col max-w-[1400px] px-2 py-4 mx-auto justify-between sm:flex-row text-center text-gray-500">
          <p className="py-4 text-sm">
            2023 Chalet Hirschblick. All rights reserved
          </p>
          <div className="flex place-content-end space-x-2  sm:w-[300px] pt-4 text-2xl">
            <a
              className="hover:text-gray-400"
              href="https://www.facebook.com/ChaletHirschblick/"
              target="blank"
              aria-label="Facebook"
            >
              <FaFacebook className="mx-4" />
            </a>

            <a
              className="hover:text-gray-400"
              href="https://www.instagram.com/chalethirschblick/"
              target="blank"
              aria-label="Instagram"
            >
              <FaInstagram className="mx-4" />
            </a>

            <a
              className="hover:text-gray-400"
              href="https://goo.gl/maps/PP4b62wDtRqov6Cq6"
              target="blank"
              aria-label="Google Business"
            >
              <FaGooglePlusG className="mx-4" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
