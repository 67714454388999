import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import cookies from "js-cookie";
import i18next from "i18next";
import "/node_modules/flag-icons/css/flag-icons.min.css";

const languages = [
  {
    code: "de",
    name: "Deutsch",
    country_code: "de",
  },
  {
    code: "en",
    name: "Englisch",
    country_code: "gb",
  },
  {
    code: "nl",
    name: "Nederlands",
    country_code: "nl",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const LanguageSelector = () => {
  const currentLanguageCode = cookies.get("i18next") || "en";

  return (
    <>
      <Menu as="div" className="inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center gap-x-1.5  px-3 py-2 text-sm  text-white ">
            <GlobeAltIcon className="h-5 w-5 text-white" />
            <ChevronDownIcon
              className="mr-1 h-3 w-3 text-white"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={`absolute  z-10 mt-2 w-48 origin-top-right divide-y divide-gray-100 rounded-sm  bg-gray-700/80 shadow-lg  focus:outline-none`}
          >
            <div className="py-1 ">
              {languages.map(({ code, name, country_code }) => (
                <Menu.Item key={country_code}>
                  {({ active }) => (
                    <a
                      href="."
                      className={classNames(
                        active ? "bg-gray-500 text-gray-300" : "text-gray-100",
                        "block px-4 py-2 text-sm"
                      )}
                      onClick={() => {
                        i18next.changeLanguage(code);
                      }}
                    >
                      <span
                        className={`fi fi-${country_code} mx-2`}
                        style={{
                          opacity: currentLanguageCode === code ? 0.3 : 1,
                        }}
                      ></span>
                      <span
                        style={{
                          opacity: currentLanguageCode === code ? 0.3 : 1,
                        }}
                      >
                        {name}
                      </span>
                    </a>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export const LanguageSelectorFooter = () => {
  const currentLanguageCode = cookies.get("i18next") || "en";

  return (
    <div>
      <ul>
        <div className="  ">
          {languages.map(({ code, name, country_code }) => (
            <li
              key={code}
              className="py-1 hover:underline hover:hover:underline-offset-4 key={country_code}"
            >
              <a
                href="."
                onClick={() => {
                  i18next.changeLanguage(code);
                }}
              >
                <span
                  className={`fi fi-${country_code} mr-2`}
                  style={{
                    opacity: currentLanguageCode === code ? 0.3 : 1,
                  }}
                ></span>
                <span
                  className="text-gray-400"
                  style={{
                    opacity: currentLanguageCode === code ? 0.3 : 1,
                  }}
                >
                  {name}
                </span>
              </a>
            </li>
          ))}
        </div>
      </ul>
    </div>
  );
};

export default LanguageSelector;
