import { useTranslation } from "react-i18next";
import cam from "../../assets/images/cam.jpg";

const HlsBlocked = () => {
  const { t } = useTranslation();
  return (
    <div className=" w-600 h-auto relative ">
      <img
        src={cam}
        alt="No cam"
        className="w-full h-full object-cover opacity-40"
      />
      <div className="absolute bottom-0 px-4 py-3 bg-gray-900/60 w-full">
        <h2 className="bold text-xl text-white tracking-wide">
          {t("webcam.notlive")}
        </h2>
      </div>
    </div>
  );
};

export default HlsBlocked;
