import { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import { useTranslation } from "react-i18next";
import PhotoAlbum from "react-photo-album";


// import optional lightbox plugins
// import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
//import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
// import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/styles.css";


import ligbad from "../../assets/images/slider/chalet/ligbad.jpg";
import sauna from "../../assets/images/slider/chalet/sauna.jpg";
import slaapkamer_1 from "../../assets/images/slider/chalet/slaapkamer-1.jpg";
import slaapkamer_2 from "../../assets/images/slider/chalet/slaapkamer-2.jpg";
import woonkamer_1 from "../../assets/images/slider/chalet/woonkamer-1.jpg";
import woonkamer_haard from "../../assets/images/slider/chalet/woonkamer-haard.jpg";
import kitchen from "../../assets/images/slider/chalet/kitchen.jpg";
import kitchen_2 from "../../assets/images/slider/chalet/kitchen-2.jpg";
import kitchen_3 from "../../assets/images/slider/chalet/kitchen-3.jpg";
import chaletSummer_2 from "../../assets/images/slider/chalet/chaletSummer-2.webp";
import chaletBalkonZomer_1 from "../../assets/images/slider/chalet/chalet-balkon-zomer-1.webp";
import chaletWinterAchter from "../../assets/images/slider/chalet/hirschblick-achterkant-sneeuw.jpg";
import chaletVoorkantWinter from "../../assets/images/slider/chalet/chalet-hirschblick-voorkant-winter.jpg";
import chaletTerrasVoor_1 from "../../assets/images/slider/chalet/chalet-terras-voor-1.webp";
import chaletAchterZomer from "../../assets/images/slider/chalet/chalet-achter-zomer.webp";
import chaletTerrasBoven_1 from "../../assets/images/slider/chalet/chalet-terras-boven-1.jpg";
import chaletTerrasBoven_2 from "../../assets/images/slider/chalet/chalet-terras-boven-2.webp";


const photos = [
  { src: woonkamer_1, width: 1920, height: 1440 },
  { src: woonkamer_haard, width: 1920, height: 1440  },
  { src: kitchen, width: 1920, height: 1440  },
  { src: kitchen_2, width: 1920, height: 1440  },
  { src: kitchen_3, width: 1920, height: 1440  },
  { src: ligbad, width: 1920, height: 1440 },
  { src: sauna, width: 1920, height: 1440  },
  { src: slaapkamer_1, width: 1920, height: 1440  },
  { src: slaapkamer_2, width: 1920, height: 1440  },
  { src: chaletAchterZomer,width: 1920, height: 1440  },
  { src: chaletTerrasBoven_1, width: 1920, height: 1440  },
  { src: chaletTerrasBoven_2, width: 1920, height: 1440  },
  { src: chaletBalkonZomer_1,width: 1920, height: 1440  },
  { src: chaletTerrasVoor_1,width: 1920, height: 1440  },
  { src: chaletVoorkantWinter, width: 567, height: 1008  },
  { src: chaletSummer_2, width: 1920, height: 1440  },
  { src: chaletWinterAchter, width: 1920, height: 1440  },
  ];

const PhotoAlbumChalet = () => {
  const { t } = useTranslation();
  const [index, setIndex] = useState(-1);

  return (
    
    <div
      id="chalethirschblickgalery"
      className="max-w-[1400px] m-auto w-full px-4 py-8 mt-8 bg-gray-50/60 shadow-lg border rounded-lg"
    >
        <div className="flex items-center flex-col md:mr-4 md:mt-0">
          <h2 className=" text-indigo-600 font-bold text-3xl sm:text-4xl md:text-4xl">
            {t("gallery.title_chalet")}
          </h2>
          <p className="text-gray-500  uppercase mb-4  tracking-wider text-xs md:text-lg">
            {t("gallery.subtitle_chalet")}
          </p>
        </div>
    


            <PhotoAlbum photos={photos} 
                        layout="rows" 
                        targetRowHeight={250} 
                        onClick={({ index }) => setIndex(index)} />

            <Lightbox
              slides={photos}
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
              // enable optional lightbox plugins
              plugins={[ Thumbnails]}
            />
      
    </div>
      
  );
};

export default PhotoAlbumChalet;
