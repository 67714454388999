import React from "react";
import TopBar from "../components/TopBar";
import Navbar from "../components/Navbar";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";

const Layout = () => {
  return (
    <div>
      <TopBar />
      <Navbar />
      {/* In the Outlet the elements from the routes will be rendered */}
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
