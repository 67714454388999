import axios from "axios";
import { toast } from "react-toastify";

//const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const SERVER_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_DEV_API_URL;

// REGISTER USER TO DATABASE
export const registerUser = async (userData) => {
  try {
    const response = await axios.post(
      `${SERVER_URL}/api/users/register`,
      userData
    );

    //console.log("Response: ", response);
    if (response.statusText === "Created") {
      toast.success("User registered successfully");
    }
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    toast.error(message);
  }
};

// LOGIN USER TO DATABASE
export const loginUser = async (userData) => {
  try {
    const response = await axios.post(
      `${SERVER_URL}/api/users/login`,
      userData
    );
    //console.log("Response: ", response);
    if (response.statusText === "OK") {
      toast.success("Login successful....");
    }
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    toast.error(message);
  }
};

// LOGOUT USER
export const logoutUser = async () => {
  try {
    await axios.get(`${SERVER_URL}/api/users/logout`);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    toast.error(message);
  }
};

// UPDATE PROFILE
export const updateUser = async (formData) => {
  try {
    const response = await axios.patch(
      `${SERVER_URL}/api/users/updateuser`,
      formData
    );
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    toast.error(message);
  }
};

// GET USERS
// export const getUsers = async () => {
//   try {
//     const response = await axios.get(`${SERVER_URL}/api/users/`);
//     console.log("response getusers:", response);
//     return response.data;
//   } catch (error) {
//     const message =
//       (error.response && error.response.data && error.response.data.message) ||
//       error.message ||
//       error.toString();
//     toast.error(message);
//   }
// };

// Get Login Status
export const getLoginStatus = async () => {
  try {
    const response = await axios.get(`${SERVER_URL}/api/users/loggedin`);
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    toast.error(message);
  }
};
