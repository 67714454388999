// import chaletSummer from "../../assets/images/home/chaletSummer.webp";
import chaletHirschblickWinterHero from "../../assets/images/winter/chalet-winter-hero.jpg";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-[90vh] ">
      <img
        src={chaletHirschblickWinterHero}
        alt="Chalet Hirschblick Winter"
        className="w-full h-full object-cover"
      />
      <div className="max-w-[1400px] m-auto">
        <div className="absolute top-[40%] w-full md:-[50%] max-w-[700px] md:max-w-[1100px] h-full flex flex-col text-white p-4 ">
          <h2 className="uppercase tracking-wide text-xs">
            Riesneralm Chalets | Donnersbachwald
          </h2>
          <h1 className="font-bold text-4xl pt-4 md:text-6xl">
            {t("home.title")}
          </h1>
          <p className="text-lg tracking-wide">{t("home.subtitle")}</p>
        </div>
      </div>
    </div>
    
  );
};

export default Hero;
