import pisteDrankje from "../../assets/images/winter/pisteDrankje.jpg";
import riesneralmPiste from "../../assets/images/winter/riesneralmPiste.jpg";
import tourSki from "../../assets/images/winter/tourski@756*1008.jpg";
import pisteCloud from "../../assets/images/winter/piste-cloud.jpg";
// import pisteViewFromLift from "../../assets/images/winter/piste-view-from-lift.jpg";
import hochsitz from "../../assets/images/winter/hochsitz.jpg";
import hochsitzEvent from "../../assets/images/winter/hochsitz-event.jpg";
import restaurantSteer from "../../assets/images/winter/restaurant-steer.jpg";

import { useTranslation } from "react-i18next";
import PhotoAlbum from "react-photo-album";

const photos = [
    { src: pisteDrankje, width: 1200, height: 1600 },
    { src: pisteCloud, width: 1008, height: 756 },
    { src: riesneralmPiste, width: 2048, height: 1536 },
    { src: tourSki, width: 756, height: 1008 },
    // { src: pisteViewFromLift, width: 1008, height: 756 },
    { src: hochsitz, width: 1008, height: 756 },
    { src: restaurantSteer, width: 1008, height: 756 },
    { src: hochsitzEvent, width: 1008, height: 756 }
  ];

const WinterInfo = () => {
  const { t } = useTranslation();
  return (
    <div
      id="chalethirschblickinfo"
      className="max-w-[1400px] m-auto w-full px-4 py-8 mt-8 bg-gray-50/60 shadow-lg border rounded-lg"
    >
      <div className="md:grid md:grid-cols-2">
        <div className="flex items-center flex-col md:mr-4 md:mt-0">
          <h2 className=" text-indigo-600 font-bold text-3xl sm:text-4xl md:text-4xl">
            {t("winterInfo.title")}
          </h2>
          <p className="text-gray-500  uppercase mb-4  tracking-wider text-xs md:text-lg">
            {t("winterInfo.subtitle")}
          </p>
          <div className="text-gray-700 mb-4 tracking-wide text-sm sd:text-sm md:text-lg">
            {t("winterInfo.content")}
            <div className="mt-4">{t("winterInfo.content_1")} </div>
            <div className="mt-4">{t("winterInfo.content_2")}</div>
            <div className="mt-4">{t("winterInfo.content_3")}</div>
          </div>
        </div>
            <div className="mx: auto">
                <PhotoAlbum rowContainerProps="flex items-center" layout="rows" targetRowHeight="240" width="100%" photos={photos} />
            </div>
      </div>
    </div>
    );
};

export default WinterInfo;
