import sleepingroom from "../../assets/images/home/sleepingroom.jpg";
//import chaletWinter from "../../assets/images/home/chalet-winter.jpg";
import hirschblickBalkon from "../../assets/images/home/hirschblick-balkon.jpg";
import livingroomFireplace from "../../assets/images/home/livingroom-fireplace.jpg";
import bathroom from "../../assets/images/home/bathroom.jpg";
import hall from "../../assets/images/home/hall.jpg";

const PhotoChalet = () => {
  return (
    <div id="gallery" className="max-w-[1400px] m-auto w-full px-4 py-16">
      {/* <h2 className="text-center text-gray-700 p-4">Gallery</h2> */}
      <div className="grid sm:grid-cols-5 gap-4">
        <div className="sm:col-span-3 col-span-2 row-span-2">
          <img
            className="w-full h-full object-cover"
            src={hirschblickBalkon}
            alt="Chalet in winter"
          />
        </div>
        <div>
          <img
            className="w-full h-full object-cover"
            src={livingroomFireplace}
            alt="Livingroom with fireplace"
          />
        </div>
        <div>
          <img
            className="w-full h-full object-cover"
            src={sleepingroom}
            alt="Sleepingroom"
          />
        </div>
        <div>
          <img
            className="w-full h-full object-cover"
            src={bathroom}
            alt="Bathroom"
          />
        </div>
        <div>
          <img className="w-full h-full object-cover" src={hall} alt="Hall" />
        </div>
      </div>
    </div>
  );
};

export default PhotoChalet;
