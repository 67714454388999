import { useMemo } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import Spinner from "../loader/Loader";

const GoogleMaps = () => {
  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  const center = useMemo(() => ({ lat: 47.36391, lng: 14.12916 }), []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  if (!isLoaded)
    return (
      <div>
        <Spinner />
      </div>
    );

  return (
    <div className="max-w-[1400px] m-auto w-full px-4 py-8 mt-16  bg-gray-50/60 shadow-lg border rounded-lg">
      <div className="flex items-center flex-col ">
        <h2 className=" text-indigo-600 font-bold text-4xl sm:text-5xl md:text-5xl">
          Chalet Hirschblick
        </h2>
        <p className="text-gray-500  uppercase mb-4  tracking-wider text-xs md:text-lg">
          Riesneralm | Donnersbachwald
        </p>
      </div>

      <GoogleMap mapContainerStyle={containerStyle} zoom={12} center={center}>
        <MarkerF position={center} />
      </GoogleMap>
    </div>
  );
};

export default GoogleMaps;
