import React, { useState } from "react";
import {
  FaBars,
  FaFacebookF,
  FaInstagram,
  FaGooglePlusG,
} from "react-icons/fa";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";
// import { ShowOnLogin } from "../protect/HiddenLinks";

const Navbar = () => {
  const { t } = useTranslation();
  const [nav, setNav] = useState(false);
  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="w-full min-h-[50px] flex justify-between items-center absolute z-10 text-white bg-gray-700/80 ">
      <ul className="hidden sm:flex px-4">
        <li className="p-4 text-sm  hover:underline hover:hover:underline-offset-8">
          <a href="/">{t("menu.home")}</a>
        </li>
        <li className="p-4 text-sm  hover:underline hover:hover:underline-offset-8">
          <a href="/winter">{t("menu.winter")}</a>
        </li>
        <li className="p-4 text-sm  hover:underline hover:hover:underline-offset-8">
          <a href="/summer">{t("menu.summer")}</a>
        </li>
        {/* <ShowOnLogin> */}
        <li className="p-4 text-sm  hover:underline hover:hover:underline-offset-8">
          <a href="/webcam">{t("menu.webcam")}</a>
        </li>
        {/* </ShowOnLogin> */}
        <li className="p-4 text-sm  hover:underline hover:hover:underline-offset-8">
          <a href="gallery">{t("menu.gallery")}</a>
        </li>
      </ul>
      <div className="flex justify-between items-center ">
        <LanguageSelector />

        <a
          className=" text-gray-300 hover:text-gray-50"
          href="https://www.facebook.com/ChaletHirschblick/"
          target="blank"
          aria-label="Facebook"
        >
          <FaFacebookF className="mx-4" />
        </a>

        <a
          className=" text-gray-300 hover:text-gray-50"
          href="https://www.instagram.com/chalethirschblick/"
          target="blank"
          aria-label="Instagram"
        >
          <FaInstagram className="mx-4" />
        </a>

        <a
          className=" text-gray-300 hover:text-gray-50"
          href="https://goo.gl/maps/PP4b62wDtRqov6Cq6"
          target="blank"
          aria-label="Google Business"
        >
          <FaGooglePlusG className="mx-4" />
        </a>
      </div>

      {/* Mobile Menu */}
      <div onClick={handleNav} className="sm:hidden z-10">
        <FaBars size={20} className="mr-4 cursor-pointer" />
      </div>
      <div
        onClick={handleNav}
        className={
          nav
            ? "overflow-y-hidden md:hidden ease-in duration-300 absolute text-gray-300 left-0- top-0 w-full h-screen bg-black/90 px-4 py-7 flex-col "
            : "absolute top-0 h-screen left-[-100%] ease-in duration-500 "
        }
      >
        <ul className="h-full w-full text-center pt-12">
          <li className="text-2xl p-4">
            <a href="/">Home</a>
          </li>
          <li className="text-2xl p-4 ">
            <a href="/winter">Winter</a>
          </li>
          <li className="text-2xl p-4 ">
            <a href="/summer">Summer</a>
          </li>
          <li className="text-2xl p-4 ">
            <a href="/webcam">Webcam</a>
          </li>
          <li className="text-2xl p-4 ">
            <a href="/gallery">Gallery</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
