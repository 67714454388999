export const LinkText = (props) => {
  return (
    <a
      className=" text-indigo-800 font-semibold "
      href={props.to || "#"}
      target="_blank"
      title={props.title || ""}
      rel="noreferrer"
    >
      {props.children}
    </a>
  );
};
