import Hero from "../../components/home/Hero";
// import ImageSliderChalet from "../../components/gallery/ImageSliderChalet";
import ImageSliderArea from "../../components/gallery/ImageSliderArea";
import PhotoAlbumChalet from "../../components/gallery/PhotoAlbumChalet";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const Gallery = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title> {t("gallery.meta_title")}</title>
        <meta name="description" content={t("gallery.meta_description")} />
        <link rel="canonical" href="https://riesneralm.com/gallery/" />
      </Helmet>

      <Hero />
      <PhotoAlbumChalet />
      <ImageSliderArea />
    </>
  );
};

export default Gallery;
