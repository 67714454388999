// src/HlsView
// import { selectHLSState, useHMSStore } from '@100mslive/react-sdk'
import Hls from "hls.js";
import { useEffect, useRef } from "react";

function HlsView() {
  const videoRef = useRef(null);
  // const hlsState = useHMSStore(selectHLSState)
  //   const hlsUrl = "http://localhost:4000/index.m3u8";
  //const hlsUrl = "http://10.66.1.58:4000/index.m3u8";
  //const hlsUrl = "https://api.riesneralm.com/index.m3u8";
  const hlsUrl = "https://api.riesneralm.com/assets/videos/ipcam/index.m3u8";

  // good working link from riesneralm.com website
  //const hlsUrl = "assets/videos/ipcam/index.m3u8";
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  var type = "application/x-mpegurl";

  if (isSafari) {
    type = "application/vnd.apple.mpegurl";
  }

  useEffect(() => {
    if (videoRef.current && hlsUrl) {
      const browserHasNativeHLSSupport = videoRef.current.canPlayType(
        "application/x-mpegurl"
      );

      if (Hls.isSupported()) {
        var hls = new Hls({
          // This configuration is required to insure that only the
          // viewer can access the content by sending a session cookie
          // to api.video service
          xhrSetup: function (xhr, url) {
            xhr.withCredentials = true; // do send cookie
          },
        });
        hls.loadSource(hlsUrl);

        hls.attachMedia(videoRef.current);
      } else if (browserHasNativeHLSSupport) {
        videoRef.current.src = hlsUrl;
      }
    }
  }, [hlsUrl]);
  return (
    <>
      <div>
        {isSafari ? (
          // <video controls width="480" height="auto">
          <video controls width="600" height="auto">
            <source src={hlsUrl} type={type}></source>
          </video>
        ) : (
          <video
            width="600"
            height="auto"
            ref={videoRef}
            controls
            type={type}
          ></video>
        )}
      </div>
    </>
  );
}

export default HlsView;
