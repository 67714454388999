import React from "react";
import Hero from "../../components/home/Hero";

import ChaletSubHeroPhotos from "../../components/home/ChaletSubHeroPhotos";
import PhotoChalet from "../../components/home/PhotosChalet";
import Facilities from "../../components/home/Facilities";
import RiesneralmChalets from "../../components/home/RiesneralmChalets";
import ChaletHirschblickInfo from "../../components/home/ChaletHirschblickInfo";
import ChaletFloors from "../../components/home/ChaletFloors";
import GoogleMaps from "../../components/home/GoogleMaps";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title> {t("home.meta_title")}</title>
        <meta name="description" content={t("home.meta_description")} />
        <link rel="canonical" href="https://riesneralm.com/" />
      </Helmet>

      <Hero />
      <ChaletSubHeroPhotos />
      <ChaletHirschblickInfo />
      {/* <ChaletFloors /> */}
      <PhotoChalet />
      <Facilities />
      <RiesneralmChalets />
      <GoogleMaps />
    </>
  );
};

export default Home;
