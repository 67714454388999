import { AiOutlineMail } from "react-icons/ai";
import logo from "../assets/images/logo-chalethirschblick.jpg";
import { useTranslation } from "react-i18next";
// import axios from "axios";

const TopBar = () => {
  const { t } = useTranslation();

  // == voor ophalen IP adres tbv logging nog niet actief
  // ==
  // const [ip, setIP] = useState("");
  // const [countryName, setCountryName] = useState("");
  // const current = new Date();
  // const date = `${current.getDate()}/${
  //   current.getMonth() + 1
  // }/${current.getFullYear()} - ${
  //   current.getHours() + ":" + current.getMinutes()
  // }`;

  // creating function to load ip address from the API
  // const getData = async () => {
  //   const res = await axios.get("https://geolocation-db.com/json/", {
  //     withCredentials: false,
  //   });
  //   console.log("Data:", res.data);
  //   setIP(res.data.IPv4);
  //   setCountryName(res.data.country_name);
  // };

  // useEffect(() => {
  //   //passing getData method to the lifecycle method

  //   getData();
  // }, []);

  // const handleClick = () => {
  //   console.log("Clicked on Button:", date, " - ", countryName, " - ", ip);
  // };

  return (
    <div>
      <div className="flex justify-between items-center px-4 py-2">
        <div className="flex items-center">
          <img
            className="h-6 w-4 mr-2"
            src={logo}
            alt="Chalet Hirschblick logo"
          />
          <h2 className="text-lg font-bold text-gray-700 md:text-xl ">
            Chalet <span className=" text-indigo-600">Hirschblick</span>
          </h2>
        </div>
        <div className="flex">
          <div className="hidden md:flex px-6 items-center">
            <a href="mailto:info@chalet-hirschblick.com">
              <div className="hidden md:flex px-6 items-center">
                <AiOutlineMail size={20} className="mr-2 text-indigo-600" />
                <p className="text-sm text-gray-700">
                  info@chalet-hirschblick.com
                </p>
              </div>
            </a>
          </div>

          <a
            href="https://www.villaforyou.com/ho-12357/AT.8953.06"
            target="blank"
          >
            <button
              // onClick={handleClick}
              className="px-4 py-2 text-xs tracking-wide "
            >
              {t("button.check_availibility")}
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
