import axios from "axios";

// const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const SERVER_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_DEV_API_URL;

const API_URL = `${SERVER_URL}/api/users/`;

// Get all Users
const getUsers = async () => {
  const response = await axios.get(API_URL);
  //console.log("getUsers called:", response.data);
  return response.data;
};

// Get User by (req user) Id
const getUser = async (id) => {
  const response = await axios.get(API_URL + "/getuser/" + id);
  return response.data;
};

const userService = {
  getUsers,
  getUser,
};

export default userService;
