import ChaletHirschblickBord from "../../assets/images/home/chalet-hirschblick-bord.jpg";

import { useTranslation } from "react-i18next";

const ChaletHirschblickInfo = () => {
  const { t } = useTranslation();
  return (
    <div
      id="chalethirschblickinfo"
      className="max-w-[1400px] m-auto w-full px-4 py-8 mt-8 bg-gray-50/60 shadow-lg border rounded-lg"
    >
      <div className="md:grid md:grid-cols-2">
        <div className="flex items-center flex-col ">
          <h2 className=" text-indigo-600 font-bold text-3xl sm:text-4xl md:text-4xl">
            {t("chalethirschblickinfo.title")}
          </h2>
          <p className="text-gray-500  uppercase mb-4  tracking-wider text-xs md:text-lg">
            {t("chalethirschblickinfo.subtitle")}
          </p>
          <p className="text-gray-700 mb-4 tracking-wide text-sm sd:text-sm md:text-lg">
            {t("chalethirschblickinfo.content")}
          </p>
        </div>
        <div className=" ">
          <img
            src={ChaletHirschblickBord}
            alt="Riesneralm chalets"
            className="w-full h-full object-cover border-4 border-white shadow-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default ChaletHirschblickInfo;
