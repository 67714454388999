const ImageCard = ({ image }) => {
  return (
    <div className="max-w-[1400px] mx-auto rounded overflow-hidden shadow-lg">
      <img src={image.src} alt={image.subtitle} className="w-full" />
      <div className="px-6 py-4">
        <div className="font-bold text-purple-500 text-center text-sm md:text-lg sm:text-lg mb-2">
          {image.title}
        </div>
        <div className="uppercase tracking-wider text-center text-gray-400 text-xs sm:text-sm md:text-sm  mb-2">
          {image.subtitle}
        </div>
      </div>
    </div>
  );
};

export default ImageCard;
