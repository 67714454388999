import ChaletsRiesneralm from "../../assets/images/home/chalets.jpg";
import { useTranslation } from "react-i18next";

const RiesneralmChalets = () => {
  const { t } = useTranslation();
  return (
    <div
      id="riesneralmchalets"
      className="max-w-[1400px] m-auto w-full px-4 py-8 mt-16  bg-gray-50/60 shadow-lg border rounded-lg"
    >
      <div className="md:grid md:grid-cols-2">
        <div className="flex items-center flex-col ">
          <h2 className=" text-indigo-600 font-bold text-4xl sm:text-5xl md:text-5xl">
            {t("riesneralmchalets.title")}
          </h2>
          <p className="text-gray-500  uppercase mb-4  tracking-wider text-xs md:text-lg">
            {t("riesneralmchalets.subtitle")}
          </p>
          <p className="text-gray-700 mb-4 tracking-wide text-sm sd:text-sm md:text-lg">
            {t("riesneralmchalets.content")}
          </p>
        </div>
        <div className=" ">
          <img
            src={ChaletsRiesneralm}
            alt="Riesneralm chalets"
            className="w-full h-full object-cover border-4 border-white shadow-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default RiesneralmChalets;
