import chaletHirschblick from "../../assets/images/summer/chalet-hirschblick-balcony.jpeg";
import { Trans, useTranslation } from "react-i18next";
import { LinkText } from "../LinkText";

const HeroInfo = () => {
  const { t } = useTranslation();
  return (
    <div className="max-w-[1400px] m-auto w-full px-4 py-8 mt-8 bg-gray-50/60 shadow-lg border rounded-lg">
      <div className="md:grid md:grid-cols-2">
        <div className=" ">
          <img
            src={chaletHirschblick}
            alt="Chalt Hirschblick Balcony"
            className="w-full h-full object-cover border-4 border-white shadow-lg"
          />
        </div>
        <div className="flex items-center flex-col mt-4 md:ml-4 md:mt-0 ">
          <h2 className=" text-indigo-600 font-bold  text-center text-3xl sm:text-4xl md:text-4xl">
            {t("heroInfo.title")}
          </h2>
          <p className="text-gray-500  uppercase mb-4  tracking-wider text-xs md:text-lg">
            {t("heroInfo.subtitle")}
          </p>
          <div className="text-gray-700 mb-4 tracking-wide text-sm sd:text-sm md:text-lg">
            <p className="mb-4  md:mt-4">{t("heroInfo.content")}</p>

            <div>
              <Trans
                i18nKey="heroInfo.content_1"
                t={t}
                components={{
                  lnk2_1: <LinkText to="https://www.chalet-hirschblick.com" />,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroInfo;
