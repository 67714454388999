import Hero from "../../components/winter/Hero";
import WinterSubHeroPhotos from "../../components/winter/WinterSubHeroPhotos";
import WinterInfo from "../../components/winter/WinterInfo";
// import Activities from "../../components/summer/Activities";
// import PhotosActiviteiten from "../../components/summer/PhotosActiviteiten";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import HeroInfo from "../../components/winter/HeroInfo";

const Winter = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title> {t("winter.meta_title")}</title>
        <meta name="description" content={t("winter.meta_description")} />
        <link rel="canonical" href="https://riesneralm.com/winter/" />
      </Helmet>

      <Hero />
      <WinterSubHeroPhotos />
      <HeroInfo />
      <WinterInfo />

      {/*       <Activities /> */}
      {/*      <PhotosActiviteiten /> */}

    </>
  );
};

export default Winter;
