import logo from "../../assets/images/home/logo-chalethirschblick.png";
import { BiBed } from "react-icons/bi";
import { IoPeopleOutline } from "react-icons/io5";
import {
  MdOutlineLiving,
  MdOutlineFireplace,
  MdOutlineMicrowave,
} from "react-icons/md";
import { TbToolsKitchen, TbTeapot } from "react-icons/tb";
import {
  GiChickenOven,
  GiBarbecue,
  GiHomeGarage,
  GiWineBottle,
} from "react-icons/gi";
import {
  CgSmartHomeRefrigerator,
  CgSmartHomeWashMachine,
} from "react-icons/cg";
import { AiOutlineWifi } from "react-icons/ai";
import { FaParking, FaShower } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { BiSpa, BiBath } from "react-icons/bi";
import {
  MdOutlineDeck,
  MdOutlineBedroomParent,
  MdOutlineLocalCafe,
} from "react-icons/md";
import { RiChargingPile2Line } from "react-icons/ri";
import { GiWoodPile, GiSkiBoot, GiToaster, GiBlender } from "react-icons/gi";
// import axios from "axios";

const Facilities = () => {
  const { t } = useTranslation();

  // === Nodig voor ophalen IP adres client voor logging werkt nog niet om vast te leggen in de database
  // const [ip, setIP] = useState("");
  // const [countryName, setCountryName] = useState("");
  // const current = new Date();
  // const date = `${current.getDate()}/${
  //   current.getMonth() + 1
  // }/${current.getFullYear()} - ${
  //   current.getHours() + ":" + current.getMinutes()
  // }`;

  // creating function to load ip address from the API
  // const getData = async () => {
  //   const res = await axios.get("https://geolocation-db.com/json/", {
  //     withCredentials: false,
  //   });

  //   console.log("Data:", res.data);
  //   setIP(res.data.IPv4);
  //   setCountryName(res.data.country_name);
  // };

  // useEffect(() => {
  //   //passing getData method to the lifecycle method

  //   getData();
  // }, []);

  // const handleClick = () => {
  //   console.log("Clicked on Button:", date, " - ", countryName, " - ", ip);
  // };

  return (
    <div
      id="facilities"
      className="max-w-[1400px] m-auto w-full px-4 py-8  bg-gray-50/60 shadow-lg border rounded-lg"
    >
      <div className=" grid grid-cols-1 md:grid-cols-3   ">
        {/* <div className="flex md:col-span-3  flex-col  items-center ">
          <h2 className=" text-4xl text-indigo-600 md:text-6xl font-bold ">
            {t("facilities.title")}
          </h2>
        </div> */}

        <div className="flex justify-center items-center flex-col ">
          <img
            src={logo}
            className="h-[200px]  md:h-[300px] "
            alt="Chalet Hirschblick logo"
          />
          <div>
            <a
              href="https://www.villaforyou.com/ho-12357/AT.8953.06"
              target="blank"
            >
              <button
                className="mt-16 mb-16 px-4 py-4 text-lg tracking-wide "
                // onClick={handleClick}
              >
                {t("button.check_availibility")}
              </button>
            </a>
          </div>
        </div>
        <div className="md:col-span-2 flex items-center flex-col">
          <div className="flex flex-col place-items-center">
            <h2 className=" text-4xl text-indigo-600 md:text-6xl font-bold ">
              {t("facilities.title")}
            </h2>

            <p className="text-gray-500  uppercase mb-4  tracking-wider text-xs md:text-lg">
              Chalet Hirschblick
            </p>
          </div>
          <div className="sd:grid md:grid md:grid-cols-2 gap-12">
            <ul className="my-2 tracking-wide text-gray-700">
              <li className="flex items-center ">
                <GiWineBottle
                  size={30}
                  className="my-2 mr-2  text-indigo-600"
                />
                {t("facilities.item_1")}
              </li>
              <li className="flex items-center ">
                <IoPeopleOutline
                  size={30}
                  className="my-2 mr-2 text-indigo-600"
                />
                {t("facilities.item_2")}
              </li>
              <li className="flex items-center ">
                <MdOutlineBedroomParent
                  size={30}
                  className="my-2 mr-2  text-indigo-600"
                />
                {t("facilities.item_3")}
              </li>

              <li className="flex items-center ">
                <BiBed size={30} className=" my-2 mr-2  text-indigo-600" />
                {t("facilities.item_4")}
              </li>
              <li className="flex items-center ">
                <BiBed size={30} className="my-2 mr-2  text-indigo-600" />
                {t("facilities.item_5")}
              </li>
              <li className="flex items-center ">
                <BiBed size={30} className="my-2 mr-2  text-indigo-600" />
                {t("facilities.item_6")}
              </li>
              <li className="flex items-center ">
                <MdOutlineLiving
                  size={30}
                  className=" my-2 mr-2  text-indigo-600"
                />
                {t("facilities.item_7")}
              </li>
              <li className="flex items-center ">
                <MdOutlineFireplace
                  size={30}
                  className="my-2 mr-2  text-indigo-600"
                />
                {t("facilities.item_8")}
              </li>
              <li className="flex items-center ">
                <TbToolsKitchen
                  size={30}
                  className="my-2 mr-2  text-indigo-600"
                />
                {t("facilities.item_9")}
              </li>
              <li className="flex items-center ">
                <MdOutlineLocalCafe
                  size={30}
                  className="my-2 mr-2  text-indigo-600"
                />
                {t("facilities.item_10")}
              </li>
              <li className="flex items-center ">
                <MdOutlineMicrowave
                  size={30}
                  className="mr-2 mr-2 text-indigo-600"
                />
                {t("facilities.item_11")}
              </li>
              <li className="flex items-center ">
                <GiChickenOven
                  size={30}
                  className="my-2 mr-2  text-indigo-600"
                />
                {t("facilities.item_12")}
              </li>
              <li className="flex items-center ">
                <TbTeapot size={30} className="my-2 mr-2  text-indigo-600" />
                {t("facilities.item_13")}
              </li>

              <li className="flex items-center ">
                <CgSmartHomeRefrigerator
                  size={30}
                  className="my-2 mr-2  text-indigo-600"
                />
                {t("facilities.item_14")}
              </li>
            </ul>
            <ul className="my-2 mr-2  text-gray-700">
              <li className="flex items-center ">
                <GiToaster size={30} className="my-2 mr-2  text-indigo-600" />
                {t("facilities.item_15")}
              </li>

              <li className="flex items-center ">
                <GiBlender size={30} className="my-2 mr-2  text-indigo-600" />
                {t("facilities.item_16")}
              </li>

              <li className="flex items-center ">
                <FaShower size={30} className="my-2 mr-2  text-indigo-600" />
                {t("facilities.item_17")}
              </li>
              <li className="flex items-center ">
                <BiSpa size={30} className="my-2 mr-2  text-indigo-600" />
                {t("facilities.item_18")}
              </li>
              <li className="flex items-center ">
                <BiBath size={30} className="my-2 mr-2  text-indigo-600" />
                {t("facilities.item_19")}
              </li>
              <li className="flex items-center ">
                <GiBarbecue
                  size={30}
                  className="md:my-2 mr-2  text-indigo-600"
                />
                {t("facilities.item_20")}
              </li>
              <li className="flex items-center ">
                <AiOutlineWifi
                  size={30}
                  className="my-2 mr-2  text-indigo-600"
                />
                {t("facilities.item_21")}
              </li>
              <li className="flex items-center ">
                <MdOutlineDeck
                  size={30}
                  className="my-2 mr-2  text-indigo-600"
                />
                {t("facilities.item_22")}
              </li>
              <li className="flex items-center ">
                <GiHomeGarage
                  size={30}
                  className="my-2 mr-2  text-indigo-600"
                />
                {t("facilities.item_23")}
              </li>
              <li className="flex items-center ">
                <FaParking size={30} className="my-2 mr-2  text-indigo-600" />
                {t("facilities.item_24")}
              </li>

              <li className="flex items-center ">
                <GiWoodPile size={30} className="my-2 mr-2  text-indigo-600" />
                {t("facilities.item_25")}
              </li>

              <li className="flex items-center ">
                <CgSmartHomeWashMachine
                  size={30}
                  className="my-2 mr-2  text-indigo-600"
                />
                {t("facilities.item_26")}
              </li>
              <li className="flex items-center ">
                <RiChargingPile2Line
                  size={30}
                  className="my-2 mr-2  text-indigo-600"
                />
                {t("facilities.item_27")}
              </li>
              <li className="flex items-center ">
                <GiSkiBoot size={30} className="my-2 mr-2  text-indigo-600" />
                {t("facilities.item_28")}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facilities;
