import { createSlice } from "@reduxjs/toolkit";

const userName = JSON.parse(localStorage.getItem("userName"));

const initialState = {
  isLoggedIn: false,
  userName: userName ? userName : "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Actions fired from frontend
    SET_LOGIN(state, action) {
      // console.log("SET_LOGIN:", action.payload);
      state.isLoggedIn = action.payload;
    },
    // Actions fired from frontend
    SET_LOGOUT(state, action) {
      //state = undefined;
      state = initialState;
    },

    SET_USERNAME(state, action) {
      localStorage.setItem("userName", JSON.stringify(action.payload));
      state.userName = action.payload;
    },
  },
});

export const { SET_LOGIN, SET_LOGOUT, SET_USERNAME } = authSlice.actions;

export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectUserName = (state) => state.auth.userName;

export default authSlice.reducer;
