import HlsView from "../../components/webcam/HlsView";
import { useTranslation } from "react-i18next";
import useRedirectLoggedOutUser from "../../customHook/useRedirectLoggedOutUser";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import HlsBlocked from "../../components/webcam/HlsBlocked";
import { Helmet } from "react-helmet-async";

const Webcam = () => {
  const { t } = useTranslation();
  useRedirectLoggedOutUser("/login");

  const [showWebcam, setShowWebcam] = useState(false);
  const localDate = new Date();
  const [sunsetDate, setSunsetDate] = useState("");

  const url =
    "https://api.sunrise-sunset.org/json?lat=47.364345412032065&lng=14.129116279379572&date=today&formatted=0";

  useEffect(() => {
    const sunUnder = async () => {
      axios.get(url, { withCredentials: false }).then((response) => {
        // setData(response.data.results.sunset);
        // console.log("data:", response.data.results.sunset);
        const sunset = moment(
          response.data.results.sunset,
          "YYYY-MM-DD hh:mm:ss"
        ).format();
        setSunsetDate(sunset);
      });

      //      if (moment().isBefore(sunsetDate) && localDate.getHours() >= 9) {
      if (
        moment().subtract(2, "hours").isBefore(sunsetDate) &&
        localDate.getHours() >= 9
      ) {
        // console.log("true --> Hours: ", localDate.getHours());
        // console.log("sunsetDate:", sunsetDate);
        console.log("moment.utc:", moment().subtract(2, "hours"));
        setShowWebcam(true);
      } else {
        // console.log("false: Hours: ", localDate.getHours());
        // console.log("sunsetDate:", sunsetDate);
        console.log("moment.utc:", moment().subtract(2, "hours"));
        // console.log(moment().isBefore(data);
        setShowWebcam(false);
      }
    };
    sunUnder();

    console.log("sunsetDate:", sunsetDate);
    // eslint-disable-next-line
  });

  return (
    <>
      <Helmet>
        <title> {t("webcam.meta_title")}</title>
        <meta name="description" content={t("webcam.meta_description")} />
        <link rel="canonical" href="https://riesneralm.com/webcam/" />
      </Helmet>

      <div className="max-w-[1400px] m-auto w-full px-4 py-8 mt-16  bg-gray-50/60 shadow-lg border rounded-lg">
        <div></div>
        <div className="md:grid md:gap-2 md:grid-cols-3 ">
          <div className="flex items-center  flex-col md:col-span-2">
            {showWebcam ? <HlsView /> : <HlsBlocked />}
          </div>

          <div className="flex items-center flex-col md:col-span-1">
            <h2 className=" text-indigo-600 font-bold mt-4 text-4xl sm:text-5xl md:text-5xl">
              {t("webcam.title")}
            </h2>
            <p className="text-gray-500  uppercase mb-4 md:mb-0  tracking-wider text-sm md:text-sm">
              {t("webcam.subtitle")}
            </p>
            <p className="text-gray-700 mt-4 mb-4 tracking-wide text-sm sd:text-sm md:text-lg">
              {t("webcam.content")}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Webcam;
