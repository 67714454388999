import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Layout from "./layout/Layout";
import Home from "./pages/home/Home";
import Webcam from "./pages/webcam/Webcam";
import Summer from "./pages/summer/Summer";
import Winter from "./pages/winter/Winter";
import { SET_LOGIN } from "./redux/store/auth/authSlice";
import Login from "./pages/auth/Login";
import Gallery from "./pages/gallery/gallery";

// widthCredentials returns the cookie from the server
axios.defaults.withCredentials = true;

function App() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log("Setting page title");
    // document.title = t("app_title");
    dispatch(SET_LOGIN(false));
  }, [t, dispatch]);

  return (
    <div>
      <BrowserRouter>
        <ToastContainer autoClose={2000} />
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/summer" element={<Summer />} />
            <Route path="/winter" element={<Winter />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/webcam" element={<Webcam />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
