import balConySummer1 from "../../assets/images/summer/balcony-summer-1.webp";
import mountainBike from "../../assets/images/summer/mountainbike.webp";
import bach from "../../assets/images/summer/bach.webp";

const SummerSubHeroPhotos = () => {
  return (
    <div className="max-w-[1140px] m-auto w-full md:flex mt-[-75px]">
      <div className="relative p-6">
        {/* <h3 className="absolute z-10 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-white text-2xl font-bold">
          kitchen
        </h3> */}
        <img
          className="w-full h-full object-cover relative border-4 border-white shadow-lg"
          src={balConySummer1}
          alt="Balcony summer view"
        />
      </div>
      <div className="relative p-6">
        {/* <h3 className="absolute z-10 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-white text-2xl font-bold">
          chalet
        </h3> */}
        <img
          className="w-full h-full object-cover relative border-4 border-white shadow-lg"
          src={bach}
          alt="Bach Donnersbachwald"
        />
      </div>
      <div className="relative p-6">
        {/* <h3 className="absolute z-10 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-white text-2xl font-bold">
          wellness
        </h3> */}
        <img
          className="w-full h-full object-cover relative border-4 border-white shadow-lg"
          src={mountainBike}
          alt="Mountainbike"
        />
      </div>
    </div>
  );
};

export default SummerSubHeroPhotos;
