import { useState } from "react";

import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import { useTranslation } from "react-i18next";

import donnersbachwald_klamm from "../../assets/images/slider/area/donnersbachwald_klamm.webp";
import bike from "../../assets/images/slider/area/bike.webp";
import chalets from "../../assets/images/slider/area/chalets.webp";
import golf_1 from "../../assets/images/slider/area/golf_1.webp";
import golf_2 from "../../assets/images/slider/area/golf_2.webp";
import golf_3 from "../../assets/images/slider/area/golf_3.webp";
import hochsitz from "../../assets/images/slider/area/hochsitz.webp";
import oxenalm_1 from "../../assets/images/slider/area/oxenalm_1.webp";
import oxenalm_2 from "../../assets/images/slider/area/oxenalm_2.webp";
import planneralm_1 from "../../assets/images/slider/area/planneralm_1.webp";
import riesneralmPiste from "../../assets/images/slider/area/riesneralm-piste.webp";
import snow_1 from "../../assets/images/slider/area/snow-1.webp";
import snow_2 from "../../assets/images/slider/area/snow-2.webp";
import tauplitz_1 from "../../assets/images/slider/area/tauplitz_1.webp";
import tauplitz_2 from "../../assets/images/slider/area/tauplitz_2.webp";
import tourski from "../../assets/images/slider/area/tourski.webp";
import view_donnersbachwald from "../../assets/images/slider/area/view_donnersbachwald.jpg";
import zwemmeer from "../../assets/images/slider/area/zwemmeer.webp";

const ImageSliderArea = () => {
  const { t } = useTranslation();
  const slides = [
    {
      url: chalets,
      title: "chalets",
    },
    {
      url: view_donnersbachwald,
      title: "view_donnersbachwald",
    },

    {
      url: donnersbachwald_klamm,
      title: "donnersbachwald_klamm",
    },
    {
      url: bike,
      title: "bike",
    },

    {
      url: golf_1,
      title: "golf-1",
    },
    {
      url: golf_2,
      title: "golf-2",
    },
    {
      url: golf_3,
      title: "golf-3",
    },
    {
      url: hochsitz,
      title: "hochsitz",
    },
    {
      url: oxenalm_1,
      title: "oxenalm_1",
    },
    {
      url: oxenalm_2,
      title: "oxenalm_2",
    },
    {
      url: planneralm_1,
      title: "planneralm_1",
    },

    {
      url: snow_1,
      title: "snow_1",
    },
    {
      url: snow_2,
      title: "snow_2",
    },
    {
      url: riesneralmPiste,
      title: "riesneralmPiste",
    },
    {
      url: tauplitz_1,
      title: "tauplitz_1",
    },
    {
      url: tauplitz_2,
      title: "tauplitz_2",
    },
    {
      url: tourski,
      title: "tourski",
    },
    {
      url: zwemmeer,
      title: "zwemmeer",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <>
      <div className="flex md:col-span-3 mt-10 flex-col  items-center ">
        <h2 className=" text-indigo-600 font-bold text-3xl sm:text-4xl md:text-4xl">
          {t("gallery.title_area")}
        </h2>
        <p className="text-gray-500  uppercase mb-4  tracking-wider text-xs md:text-lg">
          {t("gallery.subtitle_area")}
        </p>
      </div>
      <div>
        <div className="max-w-[1400px] h-[680px] md:h-[800px] w-full m-auto py-16 px-4 relative group">
          <div
            className="w-full h-full rounded-lg bg-center bg-cover duration-500"
            style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
          ></div>
          {/* Left Arrow */}
          <div className="absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 group-hover:bg-black/20 text-white cursor-pointer">
            <BsChevronCompactLeft onClick={prevSlide} size={30} />
          </div>
          {/* Right Arrow */}
          <div className="absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 group-hover:bg-black/20 text-white cursor-pointer">
            <BsChevronCompactRight onClick={nextSlide} size={30} />
          </div>

          <div className="flex top-4 justify-center py-2">
            {slides.map((slide, slideIndex) => (
              <div
                className="text-2xl cursor-pointer"
                key={slideIndex}
                onClick={() => goToSlide(slideIndex)}
              >
                <RxDotFilled />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageSliderArea;
